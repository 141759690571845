const oldestDate = new Date(0);
const oldestDateUtcString = oldestDate.toUTCString();

const validFractionStringFormat = /^[0-9]*?$/;
const validIntegerStringFormat = /^-?([0-9]|([1-9][0-9]*))$/;

export default {
  isNullOrInstanceOf(instance, classConstructor) {
    if (instance === null) return true;
    return this.isInstanceOf(instance, classConstructor);
  },

  isInstanceOf(instance, classConstructor) {
    let currentPrototype = Object.getPrototypeOf(instance);
    while (currentPrototype) {
      if (currentPrototype.constructor === classConstructor) return true;
      currentPrototype = Object.getPrototypeOf(currentPrototype);
    }
    return false;
  },

  areAllContentsInstanceOf(container, classConstructor) {
    if (this.isInstanceOf(container, Object)) container = Object.values(container);
    if (this.isInstanceOf(container, Array)) {
      for (let content of container) {
        if (!this.isInstanceOf(content, classConstructor)) return false;
      }
      return true;
    } else {
      return undefined;
    }
  },

  lowerCamelToUpperSnake(lowerCamelString) {
    return lowerCamelString.replace(/[A-Z]/g, char => ('_' + char)).toUpperCase();
  },

  padZero(number, numDigits) {
    return ('0'.repeat(numDigits) + String(number)).slice(-numDigits);
  },

  formatDateTimeForTable(datetime) {
    if (datetime === null) {
      return null;
    } else {
      return datetime.toLocaleString();
    }
  },

  min(x, y) {
    return (x > y)? y : x;
  },

  matchesIdFormat(x) {
    if (x) {
      return (x.match(/^[a-z0-9\-_]+$/) !== null);
    } else {
      return false;
    }
  },

  setCookie(key, value, expireDate) {
    let expireDateUtcString = expireDate.toUTCString();
    document.cookie = key + '=' + value + '; expires=' + expireDateUtcString + ';';
  },

  getCookie(key) {
    let cookiePairs = document.cookie.split(';').map(cookiePair => cookiePair.trim());
    let foundCookiePair = cookiePairs.find(cookiePair => cookiePair.startsWith(key + '='))
    if (foundCookiePair === undefined) return null;
    return foundCookiePair.split('=')[1];
  },

  deleteCookie(key) {
    document.cookie = key + '=; expires=' + oldestDateUtcString + ';';
  },

  matchesDecimalNumberStringFormat(x) {
    if (x.includes('.')) {
      if (x === '.') return false;
      let [ integer, fraction ] = this.getIntegerAndFractionFromDecimalNumberString(x);
      if (fraction) {
        if (fraction.match(validFractionStringFormat) === null) return false;
      }
      if (integer) {
        if (integer.match(validIntegerStringFormat) === null) return false;
      }
      return true;
    } else {
      return (x.match(validIntegerStringFormat) !== null);
    }
  },

  getIntegerAndFractionFromDecimalNumberString(numberString) {
    let decimalPointIdx = numberString.indexOf('.');
    if (decimalPointIdx > 0) {
      let integer = numberString.slice(0, decimalPointIdx);
      let fraction = numberString.slice(decimalPointIdx + 1);
      return [ integer, fraction ];
    } else {
      return [ numberString, undefined ];
    }
  },

  convertCentIntegerToDollerString(centInteger) {
    let integerPartInDoller = Math.floor(centInteger / 100);
    let fractionPartInDoller = centInteger % 100;
    let [ integerPartInDollerString ] = String(integerPartInDoller).split('.', 1);
    if (fractionPartInDoller > 0) {
      let [ fractionPartInDollerString ] = String(fractionPartInDoller).split('.', 1);
      return integerPartInDollerString + '.' + this.padZero(fractionPartInDollerString, 2);
    } else {
      return integerPartInDollerString;
    }
  },

  convertDollerStringToCentInteger(dollerString) {
    let [ integerPart, decimalPart ] = this.getIntegerAndFractionFromDecimalNumberString(dollerString);
    let integerPartInCentInteger = ((integerPart)? Number(integerPart) : 0) * 100;
    let fractionPartInCentInteger = ((decimalPart)? Number(decimalPart.slice(0, 2)) : 0);
    return integerPartInCentInteger + fractionPartInCentInteger;
  },
};
