import { ThisBound } from '@iflb/lib'
import { Duct, State, DuctLoopResponseQueue } from '@iflb/ducts-client'
import constants from './constants.js'
import codecs from './codecs.js'
import utils from './utils.js'
import lock from './async_lock.js'

class TuttiMarketDuctError extends Error {
  constructor(eventName, code, message) {
    super(eventName + ': ' + message);
    this.eventName = eventName;
    this.code = code;
    this.rawMessage = message;
  }
}

class TuttiMarketDuct extends ThisBound {
  constructor() {
    super();
    let duct = new Duct(); 
    // duct._connectionListener.onopen = (event) => {
    //   console.log('[OPEN]', event);
    // };
    // duct._connectionListener.onclose = (event) => {
    //   console.log('[CLOSE]', event);
    // };
    // duct._connectionListener.onerror = (event) => {
    //   console.log('[ERROR]', event);
    // };
    // duct._connectionListener.onmessage = (event) => {
    //   console.log('[MESSAGE]', event);
    // };
    this.instance = duct;
    this.wsdUrl = '/ducts/wsd';
    this.accessTokenLifetime = new Date(1 * 60 * 60 * 1000);
    this.errorHandler = null;
    this.loadAccountContext();
  }

  getErrorHandler(self) {
    return self.errorHandler;
  }

  setErrorHandler(self, errorHandler) {
    self.errorHandler = errorHandler;
  }

  getWsdUrl(self) {
    return self.wsdUrl;
  }

  setWsdUrl(self, wsdUrl) {
    self.wsdUrl = wsdUrl;
  }

  get accessToken() {
    return lock.acquire('ducts.accountContext', () => this._accessToken);
  }

  get userId() {
    return lock.acquire('ducts.accountContext', () => this._userId);
  }

  saveAccountContext(self, userId, accessToken) {
    let expireDate = new Date(Date.now() + self.accessTokenLifetime.getTime());
    utils.setCookie('accessToken', accessToken, expireDate);
    self._accessToken = accessToken;
    utils.setCookie('userId', userId, expireDate);
    self._userId = userId;
  }

  loadAccountContext(self) {
    self._accessToken = utils.getCookie('accessToken');
    self._userId = utils.getCookie('userId');
  }

  deleteAccountContext(self) {
    utils.deleteCookie('accessToken');
    self._accessToken = null;
    utils.deleteCookie('userId');
    self._userId = null;
  }

  async connect(self) {
    while (self.instance.state !== State.OPEN_CONNECTED) {
      switch (self.instance.state) {
        case State.CLOSE:
          await self.instance.open(self.wsdUrl);
          break;
        case State.OPEN_CLOSED:
          await self.instance.reconnect();
          break;
      }
    }
  }

  async disconnect(self) {
    while ((self.instance.state !== State.CLOSE) && (self.instance.state !== State.OPEN_CLOSED)) {
      switch (self.instance.state) {
        case State.OPEN_CONNECTED:
          await self.instance.close();
          break;
      }
    }
  }

  async call(self, eventName, params = {}) {
    await self.connect();
    let response = await self.instance.call(self.instance.EVENT[eventName], params);
    if (utils.isInstanceOf(response, DuctLoopResponseQueue)) {
      return response;
    } else {
      if (!response.success) {
        let tuttiMarketDuctError = new TuttiMarketDuctError(eventName, response.body.code, response.body.message);
        if (self.errorHandler === null) {
          throw tuttiMarketDuctError;
        } else {
          self.errorHandler(tuttiMarketDuctError);
        }
      }
      return (Object.keys(response).includes('body'))? response.body : null;
    }
  }

  async send(self, eventName, params = {}, requestId = null) {
    if (requestId === null) {
      requestId = self.instance.nextRid();
    }
    await self.connect();
    return self.instance.send(requestId, self.instance.EVENT[eventName], params);
  }

  static hashPassword(password) {
    return codecs.sha512(password);
  }
}

Object.defineProperty(
  TuttiMarketDuct,
  'EVENT',
  {
    value: {
      setup: 'SETUP',
      signUp: 'SIGN_UP',
      signIn: 'SIGN_IN',
      signOut: 'SIGN_OUT',
      isAccessTokenExpired: 'IS_ACCESS_TOKEN_EXPIRED',
      getFailureResponseReasonCode: 'GET_FAILURE_RESPONSE_REASON_CODE',
      refreshAccessToken: 'REFRESH_ACCESS_TOKEN',
      userIdExists: 'USER_ID_EXISTS',
      userProfileExists: 'USER_PROFILE_EXISTS',
      getUserProfile: 'GET_USER_PROFILE',
      createUserProfile: 'CREATE_USER_PROFILE',
      updateUserProfile: 'UPDATE_USER_PROFILE',
      isUserWorkable: 'IS_USER_WORKABLE',
      isUserWorkerManageable: 'IS_USER_WORKER_MANAGEABLE',
      isUserRequestable: 'IS_USER_REQUESTABLE',
      getAccountInfo: 'GET_ACCOUNT_INFO',
      createJobClass: 'CREATE_JOB_CLASS',
      openJobClass: 'OPEN_JOB_CLASS',
      closeJobClass: 'CLOSE_JOB_CLASS',
      expireJobClass: 'EXPIRE_JOB_CLASS',
      getJobClassIds: 'GET_JOB_CLASS_IDS',
      updateJobClass: 'UPDATE_JOB_CLASS',
      getTotalNumJobAssignments: 'GET_TOTAL_NUM_JOB_ASSIGNMENTS',
      getJobClass: 'GET_JOB_CLASS',
      getJobClassInfo: 'GET_JOB_CLASS_INFO',
      isJobClassOpen: 'IS_JOB_CLASS_OPEN',
      isJobClassOpenForWorker: 'IS_JOB_CLASS_OPEN_FOR_WORKER',
      reportProblemsAboutJobClass: 'REPORT_PROBLEMS_ABOUT_JOB_CLASS',
      getOpenJobId: 'GET_OPEN_JOB_ID',
      registerJob: 'REGISTER_JOB',
      registerJobsByParameters: 'REGISTER_JOBS_BY_PARAMETERS',
      updateJob: 'UPDATE_JOB',
      openJob: 'OPEN_JOB',
      getJob: 'GET_JOB',
      startJobAssignment: 'START_JOB_ASSIGNMENT',
      reserveJobAssignment: 'RESERVE_JOB_ASSIGNMENT',
      startReservedJobAssignment: 'START_RESERVED_JOB_ASSIGNMENT',
      finishJobAssignment: 'FINISH_JOB_ASSIGNMENT',
      cancelJobAssignment: 'CANCEL_JOB_ASSIGNMENT',
      closeJob: 'CLOSE_JOB',
      expireJob: 'EXPIRE_JOB',
      progressJobAssignment: 'PROGRESS_JOB_ASSIGNMENT',
      getJobClassReport: 'GET_JOB_CLASS_REPORT',
      getAllJobParameters: 'GET_ALL_JOB_PARAMETERS',
      isJobClassDeletable: 'IS_JOB_CLASS_DELETABLE',
      deleteJobClass: 'DELETE_JOB_CLASS',
      deleteJobClassForcibly: 'DELETE_JOB_CLASS_FORCIBLY',
      getJobAssignmentIds: 'GET_JOB_ASSIGNMENT_IDS',
      getJobClassReportIds: 'GET_JOB_CLASS_REPORT_IDS',
      getJobInfo: 'GET_JOB_INFO',
      workerInfoExists: 'WORKER_INFO_EXISTS',
      getWorkerInfo: 'GET_WORKER_INFO',
      getJobAssignment: 'GET_JOB_ASSIGNMENT',
      getUserIds: 'GET_USER_IDS',
      isUserIdAssociatedWithAccessToken: 'IS_USER_ID_ASSOCIATED_WITH_ACCESS_TOKEN',
      getWorkerGroupIds: 'GET_WORKER_GROUP_IDS',
      workerGroupExists: 'WORKER_GROUP_EXISTS',
      getWorkerGroup: 'GET_WORKER_GROUP',
      createWorkerGroup: 'CREATE_WORKER_GROUP',
      updateWorkerGroup: 'UPDATE_WORKER_GROUP',
      getWorkerGroupInfo: 'GET_WORKER_GROUP_INFO',
      workerGroupOwned: 'WORKER_GROUP_OWNED',
      getAncestorWorkerGroupIds: 'GET_ANCESTOR_WORKER_GROUP_IDS',
      requesterInfoExists: 'REQUESTER_INFO_EXISTS',
      getRequesterInfo: 'GET_REQUESTER_INFO',
      watchJob: 'WATCH_JOB',
      unwatchJob: 'UNWATCH_JOB',
      isJobWatched: 'IS_JOB_WATCHED',
      subscribeJob: 'SUBSCRIBE_JOB',
      unsubscribeJob: 'UNSUBSCRIBE_JOB',
      getJobClassTableMetaData: 'GET_JOB_CLASS_TABLE_META_DATA',
      getJobClassTableData: 'GET_JOB_CLASS_TABLE_DATA',
      getJobTableMetaData: 'GET_JOB_TABLE_META_DATA',
      getJobTableData: 'GET_JOB_TABLE_DATA',
      getUserTableMetaData: 'GET_USER_TABLE_META_DATA',
      getUserTableData: 'GET_USER_TABLE_DATA',
      getWorkerGroupTableMetaData: 'GET_WORKER_GROUP_TABLE_META_DATA',
      getWorkerGroupTableData: 'GET_WORKER_GROUP_TABLE_DATA',
      getPartyTableMetaData: 'GET_PARTY_TABLE_META_DATA',
      getPartyTableData: 'GET_PARTY_TABLE_DATA',
      getForReservingJobWorkerTableMetaData: 'GET_FOR_RESERVING_JOB_WORKER_TABLE_META_DATA',
      getForReservingJobWorkerTableData: 'GET_FOR_RESERVING_JOB_WORKER_TABLE_DATA',
      getJobAssignmentTableMetaData: 'GET_JOB_ASSIGNMENT_TABLE_META_DATA',
      getJobAssignmentTableData: 'GET_JOB_ASSIGNMENT_TABLE_DATA',
      getJobClassReportTableMetaData: 'GET_JOB_CLASS_REPORT_TABLE_META_DATA',
      getJobClassReportTableData: 'GET_JOB_CLASS_REPORT_TABLE_DATA',
      echo: 'ECHO',
      getAllKeys: 'GET_ALL_KEYS',
      deleteAllData: 'DELETE_ALL_DATA',
    },
    writable: false,
  }
);

const duct = new TuttiMarketDuct();

export default {
  get errorHandler() { return duct.getErrorHandler() },
  set errorHandler(value) { duct.setErrorHandler(value) },
  get wsdUrl() { return duct.getWsdUrl() },
  set wsdUrl(value) { duct.setWsdUrl(value) },
  async getUserId() { return await duct.userId },
  async connect() { await duct.connect() },
  async disconnect() { await duct.disconnect() },
  async invokeOnOpen(callback) { duct.instance.invokeOnOpen(callback) },

  async echo(params)         { return await duct.call(TuttiMarketDuct.EVENT.echo, params) },
  async getAllKeys()         { return await duct.call(TuttiMarketDuct.EVENT.getAllKeys) },
  async deleteAllData()      { return await duct.call(TuttiMarketDuct.EVENT.deleteAllData) },
  async userIdExists(userId) { return await duct.call(TuttiMarketDuct.EVENT.userIdExists, { user_id: userId }) },
  async getAccountInfo(userId = null) {
    if (userId === null) userId = await duct.userId;
    return await duct.call(TuttiMarketDuct.EVENT.getAccountInfo, {
      user_id: userId,
      access_token: await duct.accessToken,
    });
  },
  async setup() {
    return await duct.call(TuttiMarketDuct.EVENT.setup, {
      access_token: await duct.accessToken,
    });
  },
  async signUp(userId, password, userRoles) {
    return await duct.call(TuttiMarketDuct.EVENT.signUp, {
      user_id: userId,
      password_hash: TuttiMarketDuct.hashPassword(password),
      user_roles: userRoles,
    });
  },
  async signIn(userId, password, accessTokenLifetimeInMilliseconds = null) {
    return await lock.acquire('ducts.accountContext', async () => {
      let response = await duct.call(TuttiMarketDuct.EVENT.signIn, {
        user_id: userId,
        password_hash: TuttiMarketDuct.hashPassword(password),
        access_token_lifetime_msec: (accessTokenLifetimeInMilliseconds === null)? duct.accessTokenLifetime.getTime() : accessTokenLifetimeInMilliseconds,
      });
      duct.saveAccountContext(userId, response['access_token']);
      return response;
    });
  },
  async signOut() {
    let accessToken = await duct.accessToken;
    if (accessToken === undefined) accessToken = null;
    return await lock.acquire('ducts.accountContext', async () => {
      let response = await duct.call(TuttiMarketDuct.EVENT.signOut, {
        access_token: accessToken,
      });
      duct.deleteAccountContext();
      return response;
    });
  },
  async getFailureResponseReasonCode() {
    return await duct.call(TuttiMarketDuct.EVENT.getFailureResponseReasonCode);
  },
  async isAccessTokenExpired() {
    let accessToken = await duct.accessToken;
    if (accessToken) {
      return await duct.call(TuttiMarketDuct.EVENT.isAccessTokenExpired, {
        access_token: accessToken,
      });
    }
    return true;
  },
  async refreshAccessToken(accessTokenLifetimeInMilliseconds = null) {
    let accessToken = await duct.accessToken;
    let userId = await duct.userId;
    return await lock.acquire('ducts.accountContext', async () => {
      let response =  await duct.call(TuttiMarketDuct.EVENT.refreshAccessToken, {
        user_id: userId,
        access_token: accessToken,
        access_token_lifetime_msec: (accessTokenLifetimeInMilliseconds === null)? duct.accessTokenLifetime.getTime() : accessTokenLifetimeInMilliseconds,
      });
      duct.saveAccountContext(userId, response['access_token']);
      return response;
    });
  },
  async userProfileExists(userId = null)  {
    if (userId === null) userId = await duct.userId;
    return await duct.call(TuttiMarketDuct.EVENT.userProfileExists, {
      user_id: userId,
      access_token: await duct.accessToken,
    });
  },
  async getUserProfile(userId = null) {
    if (userId === null) userId = await duct.userId;
    return await duct.call(TuttiMarketDuct.EVENT.getUserProfile, {
      user_id: userId,
      access_token: await duct.accessToken,
    });
  },
  async createUserProfile(name, profileImageData = null, userId = null) {
    if (userId === null) userId = await duct.userId;
    return await duct.call(TuttiMarketDuct.EVENT.createUserProfile, {
      user_id: userId,
      access_token: await duct.accessToken,
      name: name,
      profile_image_data: profileImageData,
    });
  },
  async updateUserProfile(name, profileImageData = null, userId = null) {
    if (userId === null) userId = await duct.userId;
    return await duct.call(TuttiMarketDuct.EVENT.updateUserProfile, {
      user_id: userId,
      access_token: await duct.accessToken,
      name: name,
      profile_image_data: profileImageData,
    });
  },
  async isUserWorkable(userId = null) {
    if (userId === null) userId = await duct.userId;
    return await duct.call(TuttiMarketDuct.EVENT.isUserWorkable, {
      user_id: userId,
      access_token: await duct.accessToken,
    });
  },
  async isUserWorkerManageable(userId = null) {
    if (userId === null) userId = await duct.userId;
    return await duct.call(TuttiMarketDuct.EVENT.isUserWorkerManageable, {
      user_id: userId,
      access_token: await duct.accessToken,
    });
  },
  async isUserRequestable(userId = null) {
    if (userId === null) userId = await duct.userId;
    return await duct.call(TuttiMarketDuct.EVENT.isUserRequestable, {
      user_id: userId,
      access_token: await duct.accessToken,
    });
  },
  async createJobClass(url, title, jobClassParameter, description, rewards, expiredAt, closedAt, timeLimit, target_party_ids, assignability, priorityScore, jobProgressDataType) {
    return await duct.call(TuttiMarketDuct.EVENT.createJobClass, {
      access_token: await duct.accessToken,
      url: url,
      title: title,
      job_class_parameter: jobClassParameter,
      description: description,
      rewards: rewards,
      expired_at: expiredAt.getTime(),
      closed_at: (closedAt === null)? null : closedAt.getTime(),
      time_limit: timeLimit.getTime(),
      target_party_ids: target_party_ids,
      assignability: (assignability === null)? constants.jobAssignability.unlimited : assignability,
      job_progress_data_type: jobProgressDataType,
      priority_score: priorityScore,
    });
  },
  async openJobClass(jobClassId) {
    return await duct.call(TuttiMarketDuct.EVENT.openJobClass, {
      access_token: await duct.accessToken,
      job_class_id: jobClassId,
    });
  },
  async closeJobClass(jobClassId) {
    return await duct.call(TuttiMarketDuct.EVENT.closeJobClass, {
      access_token: await duct.accessToken,
      job_class_id: jobClassId,
    });
  },
  async expireJobClass(jobClassId) {
    return await duct.call(TuttiMarketDuct.EVENT.expireJobClass, {
      access_token: await duct.accessToken,
      job_class_id: jobClassId,
    });
  },
  async getJobClassIds(jobClassFilter = null) {
    let params = {
      access_token: await duct.accessToken,
    };
    if (jobClassFilter !== null) params['job_class_filter'] = jobClassFilter;
    return await duct.call(TuttiMarketDuct.EVENT.getJobClassIds, params);
  },
  async updateJobClass(jobClassId, title, description, jobClassParameter, expiredAt, closedAt, timeLimit, target_party_ids, assignability, priorityScore) {
    return await duct.call(TuttiMarketDuct.EVENT.updateJobClass, {
      access_token: await duct.accessToken,
      job_class_id: jobClassId,
      title: title,
      description: description,
      job_class_parameter: jobClassParameter,
      expired_at: expiredAt.getTime(),
      closed_at: (closedAt === null)? null : closedAt.getTime(),
      time_limit: timeLimit.getTime(),
      target_party_ids: target_party_ids,
      assignability: assignability,
      priority_score: priorityScore,
    });
  },
  async getTotalNumJobAssignments(jobClassId) {
    return await duct.call(TuttiMarketDuct.EVENT.getTotalNumJobAssignments, {
      job_class_id: jobClassId,
      access_token: await duct.accessToken,
    });
  },
  async getJobClass(jobClassId) {
    return await duct.call(TuttiMarketDuct.EVENT.getJobClass, {
      job_class_id: jobClassId,
      access_token: await duct.accessToken,
    });
  },
  async getJobClassInfo(jobClassId) {
    return await duct.call(TuttiMarketDuct.EVENT.getJobClassInfo, {
      job_class_id: jobClassId,
      access_token: await duct.accessToken,
    });
  },
  async isJobClassOpen(jobClassId) {
    return await duct.call(TuttiMarketDuct.EVENT.isJobClassOpen, {
      job_class_id: jobClassId,
      access_token: await duct.accessToken,
    });
  },
  async isJobClassOpenForWorker(jobClassId) {
    return await duct.call(TuttiMarketDuct.EVENT.isJobClassOpenForWorker, {
      job_class_id: jobClassId,
      access_token: await duct.accessToken,
    });
  },
  async reportProblemsAboutJobClass(jobClassId, report) {
    return await duct.call(TuttiMarketDuct.EVENT.reportProblemsAboutJobClass, {
      job_class_id: jobClassId,
      report: report,
      access_token: await duct.accessToken,
    });
  },
  async getOpenJobId(jobClassId) {
    return await duct.call(TuttiMarketDuct.EVENT.getOpenJobId, {
      job_class_id: jobClassId,
      access_token: await duct.accessToken,
    });
  },
  async registerJob(jobClassId, jobParameter, description, numJobAssignmentsMax, priorityScore) {
    return await duct.call(TuttiMarketDuct.EVENT.registerJob, {
      access_token: await duct.accessToken,
      job_class_id: jobClassId,
      job_parameter: jobParameter,
      description: description,
      num_job_assignments_max: numJobAssignmentsMax,
      priority_score: priorityScore,
    });
  },
  async registerJobsByParameters(jobClassId, description, jobParameters, numJobAssignmentsMax, priorityScore) {
    return await duct.call(TuttiMarketDuct.EVENT.registerJobsByParameters, {
      access_token: await duct.accessToken,
      job_class_id: jobClassId,
      job_parameters: jobParameters,
      description: description,
      num_job_assignments_max: numJobAssignmentsMax,
      priority_score: priorityScore,
    });
  },
  async updateJob(jobId, jobParameter, description, numJobAssignmentsMax, priorityScore) {
    return await duct.call(TuttiMarketDuct.EVENT.updateJob, {
      access_token: await duct.accessToken,
      job_id: jobId,
      job_parameter: jobParameter,
      description: description,
      num_job_assignments_max: numJobAssignmentsMax,
      priority_score: priorityScore,
    });
  },
  async openJob(jobId) {
    return await duct.call(TuttiMarketDuct.EVENT.openJob, {
      job_id: jobId,
      access_token: await duct.accessToken,
    });
  },
  async getJob(jobId) {
    return await duct.call(TuttiMarketDuct.EVENT.getJob, {
      job_id: jobId,
      access_token: await duct.accessToken,
    });
  },
  async startJobAssignment(jobId) {
    return await duct.call(TuttiMarketDuct.EVENT.startJobAssignment, {
      job_id: jobId,
      access_token: await duct.accessToken,
    });
  },
  async reserveJobAssignment(jobId, partyIds, autoCancelledAt) {
    return await duct.call(TuttiMarketDuct.EVENT.reserveJobAssignment, {
      job_id: jobId,
      party_ids: partyIds,
      auto_cancelled_at: (autoCancelledAt === null)? null : autoCancelledAt.getTime(),
      access_token: await duct.accessToken,
    });
  },
  async startReservedJobAssignment(jobAssignmentId) {
    return await duct.call(TuttiMarketDuct.EVENT.startReservedJobAssignment, {
      job_assignment_id: jobAssignmentId,
      access_token: await duct.accessToken,
    });
  },
  async finishJobAssignment(jobAssignmentId) {
    return await duct.call(TuttiMarketDuct.EVENT.finishJobAssignment, {
      job_assignment_id: jobAssignmentId,
      access_token: await duct.accessToken,
    });
  },
  async cancelJobAssignment(jobAssignmentId) {
    return await duct.call(TuttiMarketDuct.EVENT.cancelJobAssignment, {
      job_assignment_id: jobAssignmentId,
      access_token: await duct.accessToken,
    });
  },
  async closeJob(jobId) {
    return await duct.call(TuttiMarketDuct.EVENT.closeJob, {
      job_id: jobId,
      access_token: await duct.accessToken,
    });
  },
  async expireJob(jobId) {
    return await duct.call(TuttiMarketDuct.EVENT.expireJob, {
      job_id: jobId,
      access_token: await duct.accessToken,
    });
  },
  async progressJobAssignment(jobAssignmentId, jobProgressData) {
    return await duct.call(TuttiMarketDuct.EVENT.progressJobAssignment, {
      job_assignment_id: jobAssignmentId,
      job_progress_data: jobProgressData,
      access_token: await duct.accessToken,
    });
  },
  async getJobClassReport(jobClassReportId) {
    return await duct.call(TuttiMarketDuct.EVENT.getJobClassReport, {
      job_class_report_id: jobClassReportId,
      access_token: await duct.accessToken,
    });
  },
  async getAllJobParameters(jobClassId) {
    return await duct.call(TuttiMarketDuct.EVENT.getAllJobParameters, {
      job_class_id: jobClassId,
      access_token: await duct.accessToken,
    });
  },
  async isJobClassDeletable(jobClassId) {
    return await duct.call(TuttiMarketDuct.EVENT.isJobClassDeletable, {
      job_class_id: jobClassId,
      access_token: await duct.accessToken,
    });
  },
  async deleteJobClass(jobClassId) {
    return await duct.call(TuttiMarketDuct.EVENT.deleteJobClass, {
      job_class_id: jobClassId,
      access_token: await duct.accessToken,
    });
  },
  async deleteJobClassForcibly(jobClassId) {
    return await duct.call(TuttiMarketDuct.EVENT.deleteJobClassForcibly, {
      job_class_id: jobClassId,
      access_token: await duct.accessToken,
    });
  },
  async getJobAssignmentIds(jobAssignmentFilter = null) {
    return await duct.call(TuttiMarketDuct.EVENT.getJobAssignmentIds, {
      job_assignment_filter: jobAssignmentFilter,
      access_token: await duct.accessToken,
    });
  },
  async getJobClassReportIds(jobClassReportFilter = null) {
    let params = {
      access_token: await duct.accessToken,
    };
    if (jobClassReportFilter !== null) params['job_class_report_filter'] = jobClassReportFilter;
    return await duct.call(TuttiMarketDuct.EVENT.getJobClassReportIds, params);
  },
  async getJobInfo(jobId) {
    return await duct.call(TuttiMarketDuct.EVENT.getJobInfo, {
      access_token: await duct.accessToken,
      job_id: jobId,
    });
  },
  async workerInfoExists(userId = null) {
    if (userId === null) userId = await duct.userId;
    return await duct.call(TuttiMarketDuct.EVENT.workerInfoExists, {
      user_id: userId,
      access_token: await duct.accessToken,
    });
  },
  async getWorkerInfo(userId = null) {
    if (userId === null) userId = await duct.userId;
    return await duct.call(TuttiMarketDuct.EVENT.getWorkerInfo, {
      user_id: userId,
      access_token: await duct.accessToken,
    });
  },
  async getJobAssignment(jobAssignmentId) {
    return await duct.call(TuttiMarketDuct.EVENT.getJobAssignment, {
      job_assignment_id: jobAssignmentId,
      access_token: await duct.accessToken,
    });
  },
  async getUserIds(userRoles = null, userRoleGroups = null) {
    let params = { access_token: await duct.accessToken };
    if (userRoles) params['user_roles'] = userRoles;
    if (userRoleGroups) params['user_role_groups'] = userRoleGroups;
    return await duct.call(TuttiMarketDuct.EVENT.getUserIds, params);
  },
  async isUserIdAssociatedWithAccessToken(userId) {
    return await duct.call(TuttiMarketDuct.EVENT.isUserIdAssociatedWithAccessToken, {
      access_token: await duct.accessToken,
      user_id: userId,
    });
  },
  async getWorkerGroupIds() {
    return await duct.call(TuttiMarketDuct.EVENT.getWorkerGroupIds, {
      access_token: await duct.accessToken,
    });
  },
  async workerGroupExists(workerGroupId) {
    return await duct.call(TuttiMarketDuct.EVENT.workerGroupExists, {
      access_token: await duct.accessToken,
      worker_group_id: workerGroupId,
    });
  },
  async getWorkerGroup(workerGroupId) {
    return await duct.call(TuttiMarketDuct.EVENT.getWorkerGroup, {
      access_token: await duct.accessToken,
      worker_group_id: workerGroupId,
    });
  },
  async createWorkerGroup(workerGroupId, workerGroupName, description, ownerIds, partyIds, accessibleUserRoles) {
    return await duct.call(TuttiMarketDuct.EVENT.createWorkerGroup, {
      access_token: await duct.accessToken,
      worker_group_id: workerGroupId,
      worker_group_name: workerGroupName,
      description: description,
      owner_ids: ownerIds,
      party_ids: partyIds,
      accessible_user_roles: accessibleUserRoles,
    });
  },
  async updateWorkerGroup(workerGroupId, workerGroupName, description, ownerIds, partyIds, accessibleUserRoles) {
    return await duct.call(TuttiMarketDuct.EVENT.updateWorkerGroup, {
      access_token: await duct.accessToken,
      worker_group_id: workerGroupId,
      worker_group_name: workerGroupName,
      description: description,
      owner_ids: ownerIds,
      party_ids: partyIds,
      accessible_user_roles: accessibleUserRoles,
    });
  },
  async getWorkerGroupInfo(workerGroupId) {
    return await duct.call(TuttiMarketDuct.EVENT.getWorkerGroupInfo, {
      access_token: await duct.accessToken,
      worker_group_id: workerGroupId,
    });
  },
  async workerGroupOwned(workerGroupId) {
    return await duct.call(TuttiMarketDuct.EVENT.workerGroupOwned, {
      access_token: await duct.accessToken,
      worker_group_id: workerGroupId,
    });
  },
  async getAncestorWorkerGroupIds(workerGroupId) {
    return await duct.call(TuttiMarketDuct.EVENT.getAncestorWorkerGroupIds, {
      access_token: await duct.accessToken,
      worker_group_id: workerGroupId,
    });
  },
  async requesterInfoExists(userId) {
    if (userId === null) userId = await duct.userId;
    return await duct.call(TuttiMarketDuct.EVENT.requesterInfoExists, {
      user_id: userId,
      access_token: await duct.accessToken,
    });
  },
  async getRequesterInfo(userId) {
    if (userId === null) userId = await duct.userId;
    return await duct.call(TuttiMarketDuct.EVENT.getRequesterInfo, {
      user_id: userId,
      access_token: await duct.accessToken,
    });
  },
  async watchJob(jobId) {
    return await duct.call(TuttiMarketDuct.EVENT.watchJob, {
      job_id: jobId,
      access_token: await duct.accessToken,
    });
  },
  async unwatchJob(jobId) {
    return await duct.call(TuttiMarketDuct.EVENT.unwatchJob, {
      job_id: jobId,
      access_token: await duct.accessToken,
    });
  },
  async isJobWatched(jobId) {
    return await duct.call(TuttiMarketDuct.EVENT.isJobWatched, {
      job_id: jobId,
      access_token: await duct.accessToken,
    });
  },
  async subscribeJob(jobId) {
    return await duct.call(TuttiMarketDuct.EVENT.subscribeJob, {
      job_id: jobId,
      access_token: await duct.accessToken,
    });
  },
  async unsubscribeJob(jobId) {
    return await duct.call(TuttiMarketDuct.EVENT.unsubscribeJob, {
      job_id: jobId,
      access_token: await duct.accessToken,
    });
  },
  async getJobClassTableMetaData(jobClassFilter = null) {
    let params = {
      access_token: await duct.accessToken,
    }
    if (jobClassFilter !== null) params['job_class_filter'] = jobClassFilter;
    return await duct.call(TuttiMarketDuct.EVENT.getJobClassTableMetaData, params);
  },
  async getJobClassTableData(pageOffset, sortingKeys = null, jobClassIds = null, filterQuery = null, jobClassFilter = null, numTableRowsPerAPage = null) {
    let params = {
      access_token: await duct.accessToken,
      page_offset: pageOffset,
      timezone_offset_min: constants.timeOffsetMinFromLocalTimezone,
      sorting_keys: sortingKeys,
      job_class_ids: jobClassIds,
      filter_query: filterQuery,
    }
    if (sortingKeys !== null) params['sorting_keys'] = sortingKeys;
    if (numTableRowsPerAPage !== null) params['num_table_rows_per_a_page'] = numTableRowsPerAPage;
    if (jobClassFilter !== null) params['job_class_filter'] = jobClassFilter;
    return await duct.call(TuttiMarketDuct.EVENT.getJobClassTableData, params);
  },
  async getJobTableMetaData(jobClassId) {
    return await duct.call(TuttiMarketDuct.EVENT.getJobTableMetaData, {
      job_class_id: jobClassId,
      access_token: await duct.accessToken,
    });
  },
  async getJobTableData(jobClassId, pageOffset, sortingKeys = null, filterQuery = null, numTableRowsPerAPage = null) {
    let params = {
      access_token: await duct.accessToken,
      job_class_id: jobClassId,
      page_offset: pageOffset,
      sorting_keys: sortingKeys,
      filter_query: filterQuery,
    }
    if (numTableRowsPerAPage !== null) params['num_table_rows_per_a_page'] = numTableRowsPerAPage;
    return await duct.call(TuttiMarketDuct.EVENT.getJobTableData, params);
  },
  async getUserTableMetaData(userRoles = null, userRoleGroups = null) {
    let params = {
      access_token: await duct.accessToken,
    }
    if (userRoles) params['user_roles'] = userRoles;
    if (userRoleGroups) params['user_role_groups'] = userRoleGroups;
    return await duct.call(TuttiMarketDuct.EVENT.getUserTableMetaData, params);
  },
  async getUserTableData(pageOffset, sortingKeys = null, filterQuery = null, numTableRowsPerAPage = null, userRoles = null, userRoleGroups = null) {
    let params = {
      access_token: await duct.accessToken,
      page_offset: pageOffset,
      timezone_offset_min: constants.timeOffsetMinFromLocalTimezone,
      sorting_keys: sortingKeys,
      filter_query: filterQuery,
    }
    if (userRoles) params['user_roles'] = userRoles;
    if (userRoleGroups) params['user_role_groups'] = userRoleGroups;
    if (numTableRowsPerAPage !== null) params['num_table_rows_per_a_page'] = numTableRowsPerAPage;
    return await duct.call(TuttiMarketDuct.EVENT.getUserTableData, params);
  },
  async getWorkerGroupTableMetaData() {
    return await duct.call(TuttiMarketDuct.EVENT.getWorkerGroupTableMetaData, {
      access_token: await duct.accessToken,
    });
  },
  async getWorkerGroupTableData(pageOffset, sortingKeys = null, filterQuery = null, numTableRowsPerAPage = null) {
    let params = {
      access_token: await duct.accessToken,
      page_offset: pageOffset,
      timezone_offset_min: constants.timeOffsetMinFromLocalTimezone,
      sorting_keys: sortingKeys,
      filter_query: filterQuery,
    }
    if (numTableRowsPerAPage !== null) params['num_table_rows_per_a_page'] = numTableRowsPerAPage;
    return await duct.call(TuttiMarketDuct.EVENT.getWorkerGroupTableData, params);
  },
  async getPartyTableMetaData(workerGroupId) {
    return await duct.call(TuttiMarketDuct.EVENT.getPartyTableMetaData, {
      worker_group_id: workerGroupId,
      access_token: await duct.accessToken,
    });
  },
  async getPartyTableData(workerGroupId, pageOffset, sortingKeys = null, filterQuery = null, numTableRowsPerAPage = null) {
    let params = {
      access_token: await duct.accessToken,
      worker_group_id: workerGroupId,
      page_offset: pageOffset,
      sorting_keys: sortingKeys,
      filter_query: filterQuery,
    }
    if (numTableRowsPerAPage !== null) params['num_table_rows_per_a_page'] = numTableRowsPerAPage;
    return await duct.call(TuttiMarketDuct.EVENT.getPartyTableData, params);
  },
  async getForReservingJobWorkerTableMetaData() {
    return await duct.call(TuttiMarketDuct.EVENT.getForReservingJobWorkerTableMetaData, {
      access_token: await duct.accessToken,
    });
  },
  async getForReservingJobWorkerTableData(pageOffset, sortingKeys = null, filterQuery = null, numTableRowsPerAPage = null) {
    let params = {
      access_token: await duct.accessToken,
      page_offset: pageOffset,
      sorting_keys: sortingKeys,
      filter_query: filterQuery,
    }
    if (numTableRowsPerAPage !== null) params['num_table_rows_per_a_page'] = numTableRowsPerAPage;
    return await duct.call(TuttiMarketDuct.EVENT.getForReservingJobWorkerTableData, params);
  },
  async getJobAssignmentTableMetaData(jobAssignmentFilter = null) {
    let params = {
      access_token: await duct.accessToken,
    }
    if (jobAssignmentFilter !== null) params['job_assignment_filter'] = jobAssignmentFilter;
    return await duct.call(TuttiMarketDuct.EVENT.getJobAssignmentTableMetaData, params);
  },
  async getJobAssignmentTableData(pageOffset, sortingKeys = null, filterQuery = null, jobAssignmentFilter = null, numTableRowsPerAPage = null) {
    let params = {
      access_token: await duct.accessToken,
      page_offset: pageOffset,
      timezone_offset_min: constants.timeOffsetMinFromLocalTimezone,
      sorting_keys: sortingKeys,
      filter_query: filterQuery,
    }
    if (sortingKeys !== null) params['sorting_keys'] = sortingKeys;
    if (numTableRowsPerAPage !== null) params['num_table_rows_per_a_page'] = numTableRowsPerAPage;
    if (jobAssignmentFilter !== null) params['job_assignment_filter'] = jobAssignmentFilter;
    return await duct.call(TuttiMarketDuct.EVENT.getJobAssignmentTableData, params);
  },
  async getJobClassReportTableMetaData(jobClassReportFilter = null) {
    let params = {
      access_token: await duct.accessToken,
    }
    if (jobClassReportFilter !== null) params['job_class_report_filter'] = jobClassReportFilter;
    return await duct.call(TuttiMarketDuct.EVENT.getJobClassReportTableMetaData, params);
  },
  async getJobClassReportTableData(pageOffset, sortingKeys = null, filterQuery = null, jobClassReportFilter = null, numTableRowsPerAPage = null) {
    let params = {
      access_token: await duct.accessToken,
      page_offset: pageOffset,
      timezone_offset_min: constants.timeOffsetMinFromLocalTimezone,
      sorting_keys: sortingKeys,
      filter_query: filterQuery,
    }
    if (sortingKeys !== null) params['sorting_keys'] = sortingKeys;
    if (numTableRowsPerAPage !== null) params['num_table_rows_per_a_page'] = numTableRowsPerAPage;
    if (jobClassReportFilter !== null) params['job_class_report_filter'] = jobClassReportFilter;
    return await duct.call(TuttiMarketDuct.EVENT.getJobClassReportTableData, params);
  },
}

export { TuttiMarketDuctError }
